/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 63.1.8-v202410240647-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqAssetsApiClass {
 
   constructor() {}

  /**
   * @summary Archive an asset
   * @param {string} id - ID of the asset to archived
   */
  public archiveAsset(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/assets/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ArchiveOutputV1>;
  }

  /**
   * @summary Create or update multiple assets at one time
   */
  public batchCreateAssets(
    body?: models.AssetBatchInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/assets/batch`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemBatchOutputV1>;
  }

  /**
   * @summary Create an asset
   */
  public createAsset(
    body: models.AssetInputV1,
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/assets`,
      data: body,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetOutputV1>;
  }

  /**
   * @summary Get an asset
   * @param {string} id - ID of the asset to retrieve
   */
  public getAsset(
    {
      id
    } : {
      id: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(id)) {
      throw new Error("'id' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/assets/${encodeURIComponent(String(id))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.AssetOutputV1>;
  }

  /**
   * @summary Get a collection of assets
   * @param {string} [scopedTo] - The ID of the workbook to which the items are scoped&lt;br&gt;If the scopedTo is set to the Empty GUID then the scoped items are excluded
   * @param {number} [offset=0] - The pagination offset, the index of the first collection item that will be returned in this page of results
   * @param {number} [limit=40] - The pagination limit, the total number of collection items that will be returned in this page of results
   */
  public getAssets(
    {
      scopedTo,
      offset,
      limit
    } : {
      scopedTo?: string,
      offset?: number,
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/assets`,

      params: omitBy({
        ['scopedTo']: scopedTo,
        ['offset']: offset,
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.ItemPreviewListV1>;
  }

}


export const sqAssetsApi = new sqAssetsApiClass();
